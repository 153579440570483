import FormPage from '@/components/FormPage'
import { createGetParams, createPostParams } from '@/rest'
import {
  formatAddress,
  formatPhoneNumber,
  getRm,
  getNumeralFromRm,
} from '@/utils'

const formTitle = 'COBP to Black'

export default {
  extends: FormPage,
  data () {
    return {
      effective: null,
      billingAddress: {
        street1: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: 'Street',
          props: {
            label: 'Enter street',
            required: true,
            rules: [val => !!val || 'Street required!'],
            validateOnBlur: true,
          },
        },
        street2: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: '',
          props: {
            label: 'Enter line 2 of street',
            rules: [],
          },
        },
        city: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: 'City',
          props: {
            label: 'Enter city',
            required: true,
            rules: [val => !!val || 'City required!'],
          },
        },
        postalCode: {
          component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
          name: 'Postal code',
          props: {
            label: 'Enter postal code',
            mask: '#####',
            maxlength: 5,
            required: true,
            rules: [
              val => !!val || 'Postal code required!',
              val => (val && val.length === 5) || 'Invalid postal code!',
            ],
            validateOnBlur: true,
          },
        },
        state: {
          component: () => import(/* webpackChunkName: "stateProvinceField" */ '@/components/StateProvinceField'),
          props: {
            required: true,
            rules: [val => !!val || 'State required!'],
          },
          name: 'State/Province',
        },
      },
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: false, to: '/dealer_kits',
        },
        {
          text: 'Black', disabled: false, to: '/black_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
      source: null,
      title: formTitle,
      // Form steps
      subscription: {
        fields: {
          msisdn: {
            component: () => import(/* webpackChunkName: "msisdnField" */ '@/components/MsisdnField'),
            name: 'Mobile number',
            props: {
              existing: true,
              noBlack: true,
              hint: 'Prepaid mobile number',
              label: 'Enter mobile number',
              presistentHint: true,
              required: true,
              rules: [],
              searchNetwork: true,
              validateOnBlur: true,
            },
          },
          nric: {
            component: () => import(/* webpackChunkName: "personalIdField" */ '@/components/PersonalIdField'),
            name: 'Nric',
            props: {
              label: 'Enter Nric number',
              validateOnBlur: true,
              rules: [],
            },
          },
          package: {
            component: () => import(/* webpackChunkName: "blackPackagesField" */ '@/components/BlackPackagesField'),
            name: 'Black package',
            props: { msisdn: null },
          },
          studentFront: {
            name: 'Student Front',
            hide: true,
            component: () => import(/* webpackChunkName: "fileInputField" */ '@/components/FileInputField'),
            props: {
              hint: 'Upload jpg format',
              label: 'Upload front student card ',
              rules: [],
              required: false,
            },
          },
          studentBack: {
            name: 'Student Back',
            hide: true,
            component: () => import(/* webpackChunkName: "fileInputField" */ '@/components/FileInputField'),
            props: {
              hint: 'Upload jpg format',
              label: 'Upload back student card ',
              rules: [],
              required: false,
            },
          },
          studentId: {
            component: () => import(/* webpackChunkName: "textField" */ '@/components/TextField'),
            name: 'Student ID',
            hide: true,
            props: {
              hint: 'Exactly as in student card',
              label: 'Enter student ID',
              rules: [],
              required: false,
            },
          },
          uniState: {
            component: () => import(/* webpackChunkName: "iptastateField" */ '@/components/IptaStateField'),
            name: 'University state',
            hide: true,
            value: this.idstate,
            rules: [],
            required: false,
          },
          unipackage: {
            component: () => import(/* webpackChunkName: "iptapackagesField" */ '@/components/IptaPackagesField'),
            name: 'University',
            hide: true,
            props: {
              list: [],
              itemValue: 'idipta',
              itemText: 'iptaname',
              label: 'Select university',
              returnObject: true,
              required: false,
            },
            value: { itemText: null, itemValue: null },
          },
          yearOfStudy: {
            component: () => import(/* webpackChunkName: "yearStudyField" */ '@/components/YearStudyField'),
            name: 'Year Of Study',
            hide: true,
            props: {
              hint: 'Year of Study',
              label: 'Enter year of study',
              rules: [],
              required: false,
            },
          },
          ecash: {
            name: 'E-recharge balance',
          },
          requiredEcash: {
            name: 'Required e-recharge',
            strong: true,
          },
        },
        subTitle: 'Current subscription + package selection',
        title: 'Subscription',
        validateNext: () => true,
      },
      contactDetails: {
        fields: {
          name: {
            name: 'Name',
          },
          contactNumber: {
            component: () => import(/* webpackChunkName: "phoneField" */ '@/components/PhoneField'),
            name: 'Contact number',
            props: {
              hint: 'Contactable land or mobile number',
              label: 'Enter contact number',
              required: true,
              rules: [
                val => !!val || 'Contact number required!',
              ],
            },
          },
          billDelivery: {
            component: () => import(/* webpackChunkName: "billDeliveryField" */ '@/components/BillDeliveryField'),
            name: 'Bill delivery',
          },
          email: {
            component: () => import(/* webpackChunkName: "unrestrictedEmailField" */ '@/components/UnrestrictedEmailField'),
            name: 'Email',
            props: {
              clearable: true,
              label: 'Enter email address',
              rules: [],
              validateOnBlur: true,
              required: true,
            },
          },
          street1: {
            name: 'Street',
          },
          street2: {
            name: '',
          },
          city: {
            name: 'City',
          },
          postalCode: {
            name: 'Postal code',
          },
          state: {
            name: 'State',
          },
          billingAddress: {
            component: () => import(/* webpackChunkName: "addressField" */ '@/components/AddressField'),
            fields: {},
            name: 'Billing address',
          },
        },
        subTitle: 'Contact & billing information',
        title: 'Contact details',
      },
      submission: {
        fields: {
          msisdn: {
            name: 'Mobile number',
          },
          package: {
            name: 'Black package',
          },
          name: {
            name: 'Name',
          },
          nric: {
            name: 'Nric',
          },
          studentId: {
            name: 'Student ID',
            hide: true,
          },
          unipackage: {
            name: 'University',
            hide: true,
          },
          yearOfStudy: {
            name: 'Year Of Study',
            hide: true,
          },
          contactNumber: {
            name: 'Contact number',
          },
          billDelivery: {
            name: 'Bill delivery',
          },
          email: {
            name: 'Email',
          },
          billingAddress: {
            name: 'Billing address',
          },
          requiredEcash: {
            strong: true,
            name: 'Required e-recharge',
          },
          chargeable: {
            emphasize: true,
            name: 'Chargeable amount',
          },
        },
        submit: false,
        errorMessage: null,
        status: null,
        subTitle: 'Check before submit',
        title: 'Submit',
      },
    }
  },
  computed: {
    // Overridden
    formSteps: function () {
      return {
        subscription: this.subscription,
        contactDetails: this.contactDetails,
        submission: this.submission,
      }
    },
    uni: function () {
      return this.subscription.fields.unipackage.value
    },
    ecash: function () {
      return this.subscription.fields.ecash
    },
    msisdn: function () {
      return this.subscription.fields.msisdn
    },
    nric: function () {
      return this.subscription.fields.nric
    },
    package: function () {
      return this.subscription.fields.package
    },
    requiredEcash: function () {
      if (!this.subscription.fields.package.value) return null
      return (Number(((this.subscription.fields.package.value.price) - 10) / 2) + 10).toFixed(2)
    },
    address () {
      const address = formatAddress(this.contactDetails.fields)
      if (!this.contactDetails.fields.billingAddress.value) {
        this.submission.fields.billingAddress.value = address
      }
      return address
    },
    billToAddress () {
      return formatAddress(this.contactDetails.fields.billingAddress.fields)
    },
  },
  mounted: function () {
    this.msisdn.props.rules.push(this.validateMsisdn)
    this.nric.props.rules.push(this.validateNric)

    this.contactDetails.fields.billingAddress.fields = this.billingAddress
    const params = createGetParams({
      erecharge: true,
    })
    this.$rest.get('getDealerResources.php', params).then(function (response) {
      this.ecash.error = null
      this.ecash.value = getRm(response.data.erecharge)
    }.bind(this)).catch(error => {
      this.ecash.error = error.message
    })

    this.subscription.validateNext = this.validateForCobp
  },
  watch: {
    'msisdn.value': function () {
      this.submission.fields.msisdn.value = formatPhoneNumber(this.subscription.fields.msisdn.value)
    },
    'nric.value': function () {
    },
    'package.value': {
      handler (val) {
        if (!val) return

        const requiredEcash = this.requiredEcash
        this.subscription.fields.requiredEcash.value = getRm(requiredEcash)
        if (requiredEcash > getNumeralFromRm(this.ecash.value)) {
          this.subscription.fields.requiredEcash.error = 'Insufficient balance!'
        } else {
          this.subscription.fields.requiredEcash.error = null
        }
        if (this.subscription.fields.package.value) {
          if (this.subscription.fields.package.value.code === 'MYSISWA35') {
            this.subscription.fields.uniState.hide = false
            // this.subscription.fields.uniState.props.required = true
            // this.subscription.fields.uniState.props.rules.push(
            //   val => !!val || 'University State is required!',
            // )
            this.subscription.fields.studentFront.hide = false
            // this.subscription.fields.studentFront.props.required = true
            // this.subscription.fields.studentFront.props.rules.push(
            //   val => !!val || 'Student Front is required!',
            // )
            this.subscription.fields.studentBack.hide = false
            // this.subscription.fields.studentBack.props.required = true
            // this.subscription.fields.studentBack.props.rules.push(
            //   val => !!val || 'Student Back is required!',
            // )
            this.subscription.fields.studentId.hide = false
            // this.subscription.fields.studentId.props.required = true
            // this.subscription.fields.studentId.props.rules.push(
            //   val => !!val || 'Student ID is required!',
            // )
            this.subscription.fields.unipackage.hide = false
            // this.subscription.fields.unipackage.props.required = true
            this.subscription.fields.yearOfStudy.hide = false
            // this.subscription.fields.yearOfStudy.props.required = true
            // this.subscription.fields.yearOfStudy.props.rules.push(
            //   val => !!val || 'Year Of Study required!',
            // )
            this.submission.fields.studentId.hide = false
            this.submission.fields.unipackage.hide = false
            this.submission.fields.yearOfStudy.hide = false
          } else if (this.subscription.fields.package.value.code !== 'MYSISWA35') {
            this.subscription.fields.uniState.hide = true
            this.subscription.fields.studentId.hide = true
            this.subscription.fields.studentFront.hide = true
            this.subscription.fields.studentBack.hide = true
            this.subscription.fields.unipackage.hide = true
            this.subscription.fields.yearOfStudy.hide = true
            this.submission.fields.studentId.hide = true
            this.submission.fields.unipackage.hide = true
            this.submission.fields.yearOfStudy.hide = true
          } else {
            this.subscription.fields.plan.error = null
          }
        }
        this.subscription.fields.requiredEcash.validate && this.subscription.fields.requiredEcash.validate(true)
        this.submission.fields.requiredEcash.value = this.subscription.fields.requiredEcash.value
        this.submission.fields.chargeable.value = getRm(Number(requiredEcash))
        this.submission.fields.package.value = this.package.value.name
      },
      deep: true,
    },
    'subscription.fields.studentId.value': function (val) {
      this.submission.fields.studentId.value = val
    },

    'subscription.fields.uniState.value': function (val) {
      const params = createGetParams({
        idstate: this.subscription.fields.uniState.value,
      })
      this.$rest.get('getUniversityName.php', params)
        .then(response => {
          this.subscription.fields.unipackage.props.list = response.data
        })
    },
    uni: function (val) {
      if (!val) return
      this.submission.fields.unipackage.value = val.iptaname
    },
    'subscription.fields.yearOfStudy.value': function (val) {
      this.submission.fields.yearOfStudy.value = val
    },
    'contactDetails.fields.contactNumber.value': function (val) {
      this.submission.fields.contactNumber.value = formatPhoneNumber(val)
    },
    'contactDetails.fields.email.value': function (val) {
      this.submission.fields.email.value = val
    },
    'contactDetails.fields.billDelivery.value': function (val) {
      if (val === 'email') {
        this.submission.fields.billDelivery.value = 'Bill via email'
        this.contactDetails.fields.email.props.required = true
        this.contactDetails.fields.email.props.rules.push(
          val => !!val || 'Email required!',
          val => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/.test(val) || 'Invalid email!',
        )
      } else {
        this.submission.fields.billDelivery.value = 'Deliver printed bill'
        this.contactDetails.fields.email.props.required = false
        this.contactDetails.fields.email.props.rules.splice(0, 20)
        this.contactDetails.fields.email.validate && this.$nextTick(() => this.contactDetails.fields.email.validate())
      }
    },
    'contactDetails.fields.billingAddress.value': function (val) {
      this.submission.fields.billingAddress.value = val
        ? formatAddress(this.contactDetails.fields.billingAddress.fields)
        : this.address
    },
    'contactDetails.fields.billingAddress.fields.street1.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.street2.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.city.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.postalCode.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
    'contactDetails.fields.billingAddress.fields.state.value': function () {
      this.submission.fields.billingAddress.value = this.billToAddress
    },
  },
  methods: {
    // Override
    async submit () {
      const payload = {
        plan: this.subscription.fields.package.value.code,
        msisdn: this.subscription.fields.msisdn.value,
        nric: this.subscription.fields.nric.value,
        name: this.contactDetails.fields.name.value,
        contact: this.contactDetails.fields.contactNumber.value,
        email: this.contactDetails.fields.email.value,
        printedBill: this.contactDetails.fields.billDelivery.value !== 'email',
        street1: this.contactDetails.fields.street1.value,
        street2: this.contactDetails.fields.street2.value,
        city: this.contactDetails.fields.city.value,
        postalCode: this.contactDetails.fields.postalCode.value,
        state: this.contactDetails.fields.state.value,
        billingAddress: null,
        effective: this.effective,
        idiptas: this.subscription.fields.unipackage.value.idipta,
        studentId: this.subscription.fields.studentId.value,
        yearOfStudy: this.subscription.fields.yearOfStudy.value,
        studentIdFrontImage: this.subscription.fields.studentFront.value,
        studentIdBackImage: this.subscription.fields.studentBack.value,

      }

      if (this.contactDetails.fields.billingAddress.value) {
        payload.billingAddress = {
          street1: this.contactDetails.fields.billingAddress.fields.street1.value,
          street2: this.contactDetails.fields.billingAddress.fields.street2.value,
          city: this.contactDetails.fields.billingAddress.fields.city.value,
          postalCode: this.contactDetails.fields.billingAddress.fields.postalCode.value,
          state: this.contactDetails.fields.billingAddress.fields.state.value,
        }
        // Fix bug where street2 is not submitted as a property
        if (!payload.billingAddress.street2) payload.billingAddress.street2 = ''
      }

      const params = createPostParams({
        action: 'cobp',
        payload: payload,
      })

      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.$rest.post('postAction.php', params)
        .then(function (response) {
          this.alertText = 'The COBP to Black was successfully submitted for processing.'
          this.status = this.submission.status = 'success'
          this.submission.submit = false
          if (this.isImpersonated()) {
            this.showSubmissionResponse(response.data)
          } else {
            this.$nextTick(() => {
              this.downloadReceipt()
            })
          }
        }.bind(this)).catch(e => {
          if (this.$rest.isCancel(e)) {
            this.alertText = 'The COBP to Black was successfully submitted for processing.'
            this.status = this.submission.status = 'success'
            this.submission.submit = false
          } else {
            this.status = this.submission.status = 'error'
            this.submission.errorMessage = e.message + ': ' + e.response.data
            this.submission.submit = false
          }
        })

      setTimeout(() => {
        this.source.cancel('Forced timeout.')
        this.source = null
      }, 9000)
    },
    cancelRequest: function () {
      if (this.source) {
        this.source.cancel('Operation canceled by the user.')
        this.source = null
      }
    },
    validateMsisdn: function () {
      if (!this.msisdn.value) return 'Mobile number required!'

      if (this.msisdn.value.length < 10 ||
        (this.msisdn.value.length === 10 && this.msisdn.value.startsWith('011'))) return 'Invalid MSISDN!'
      return true
    },
    validateNric: function () {
      if (!this.nric.value) return 'NRIC number required!'
      if (this.nric.value.length < 6 ||
        (this.nric.value.length < 12 && this.nric.props.mask === '######-##-####')) return 'Invalid NRIC'
      return true
    },
    async validateForCobp () {
      const parameters = {
        msisdn: this.msisdn.value,
        nric: this.nric.value,
      }
      this.package.value && Object.assign(parameters, { requestedPlan: this.package.value.code })
      const params = createGetParams(parameters)
      const response = await this.$rest.get('validateForCobp.php', params)
      this.contactDetails.fields.name.value = response.data.name
      this.contactDetails.fields.street1.value = response.data.postalAddress
      this.contactDetails.fields.street2.value = response.data.postalAddressL2
      this.contactDetails.fields.city.value = response.data.city
      this.contactDetails.fields.postalCode.value = response.data.postalCode
      this.contactDetails.fields.state.value = response.data.state
      this.submission.fields.name.value = response.data.name
      this.submission.fields.nric.value = response.data.nric
      this.submission.fields.billingAddress.value = formatAddress(this.contactDetails.fields)
      this.effective = response.data.effective
    },
    async downloadReceipt () {
      const payload = {
        items: [{
          msisdn: this.subscription.fields.msisdn.value,
          plan: this.subscription.fields.package.value.code,
        }],
        street1: this.contactDetails.fields.street1.value,
        street2: this.contactDetails.fields.street2.value,
        city: this.contactDetails.fields.city.value,
        postalCode: this.contactDetails.fields.postalCode.value,
        state: this.contactDetails.fields.state.value,
      }

      const data = process.env.NODE_ENV === 'production' ? { payload: payload }
        : { impersonate: 223992, payload: payload }
      /* eslint-disable no-empty */
      try {
        const response = await this.$rest({
          url: process.env.NODE_ENV === 'production' ? location.protocol + '//' + location.hostname + '/api/pdf/registrationReceipt.php'
            : 'http://onesys.onexox.my/pdf/registrationReceipt.php',
          method: 'POST',
          responseType: 'blob',
          data: data,
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')

        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', 'black_registration_resit.pdf')
        document.body.appendChild(link)
        link.click()
      } catch (e) {}
    },
  },
}
